import React, { Fragment } from 'react'
import { Typography, Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import Draggable from 'react-draggable';
import './intervalBar.css';
import { Tooltip } from '@mui/material';
import HorarioInputs from './horario-inputs';
import { Intervals } from "../../types/guardian-types";

const weekDays: Record<string, string> = {
    "0": 'Segunda',
    "1": 'Terça',
    "2": 'Quarta',
    "3": 'Quinta',
    "4": 'Sexta',
    "5": 'Sábado',
    "6": 'Domingo',
}

const daysEnum = {
    'Segunda': 'isMonday',
    'Terça': 'isTuesday',
    'Quarta': 'isWednesday',
    'Quinta': 'isThursday',
    'Sexta': 'isFriday',
    'Sábado': 'isSaturday',
    'Domingo': 'isSunday',
}

interface Props {
    openModal: boolean;
    intervals: Intervals[];
    closeModal: (isEntry: boolean) => void;
    isEntrada: boolean;
    selectedDay: string;
    setModalSettings: (isEntrada: boolean, selectedDay: string) => void;
    handleSave: (intervals: Intervals[], isEntry: boolean) => void;
}

export const WeekComponent = (props: Props) => {
    const { 
        intervals, 
        closeModal,
        openModal, 
        selectedDay,
        handleSave,
        isEntrada, 
        setModalSettings
    } = props;

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(480));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

    const multiplier = mobile ? 11 : isSmall ? 13 : isMedium ? 16 : 22;

    const handleLocalSave = (newIntervals: Intervals[]) => {
        let newIntervalsWithNewInterval = intervals.filter((interval) => interval[selectedDay] === false);
        newIntervalsWithNewInterval = [...newIntervalsWithNewInterval, ...newIntervals];

        handleSave(newIntervalsWithNewInterval, isEntrada);
    }

    const handleDrag = (ui: any, intervalId: number, dayChanged: string) => {
        const { x } = ui;
        let newIntervals = [...intervals];
        let selectedInterval = newIntervals.find((interval) => interval.id === intervalId);
        if (!selectedInterval) return;
        
        const valorHorario = (x / multiplier);
        const hora = Math.floor(valorHorario);
        let minuto = ((valorHorario - hora) * 60)
        if (minuto % 15 !== 0) {
            minuto = (Math.floor(minuto / 15) * 15);
        }

        const horaEmString = hora < 10 ? `0${hora}` : `${hora}`;
        const minutoEmString = minuto < 10 ? `0${minuto}` : `${minuto}`;
        const startedValue = `${horaEmString}:${minutoEmString}:00`;

        let createAnotherInterval = false;
        const daysininterval = Object.values(daysEnum).filter(day => selectedInterval[day] === true);
        if (daysininterval.length > 1) {
            createAnotherInterval = true;
        }
        if(createAnotherInterval) {
            let newIntervalsWithNewInterval = newIntervals.map(interval => {
                if(interval.id === selectedInterval.id) {
                    let updatedInterval = { ...interval };
                    Object.values(daysEnum).forEach(day => {
                        if (day !== dayChanged) {
                            updatedInterval[day] = false;
                        }
                    });
                    return updatedInterval;
                }
                return interval;
            });

            let newInterval: Intervals = {
                ...selectedInterval,
                id: intervals.length + 1,
                [dayChanged]: false,
            };
            newIntervals = [...newIntervalsWithNewInterval, newInterval];
        } else {
            selectedInterval.endTime = calculateEndTime(startedValue, selectedInterval.endTime, selectedInterval.startTime);
            selectedInterval.startTime = startedValue;
            newIntervals.map(interval => {
                if(interval.id === selectedInterval.id) {
                    return selectedInterval;
                }
                return interval;
            });
        }

        handleSave(newIntervals, isEntrada);
    };

    const calculateEndTime = (newStartTime: string, oldEndTime: string, oldStartTime: string) => {
        const [newHour, newMinute] = newStartTime.split(':').map(parseFloat);
        const [oldHour, oldMinute] = oldEndTime.split(':').map(parseFloat);
        const [oldStartHour, oldStartMinute] = oldStartTime.split(':').map(parseFloat);
        let endHour = newHour + (oldHour - oldStartHour);
        let endMinute = newMinute + (oldMinute - oldStartMinute);
        if (endHour >= 24) {
            endHour = 23;
            endMinute = 59;
        } else if (endMinute >= 60) {
            endHour += 1;
            endMinute = 0;
        }
        return `${endHour}:${endMinute < 10 ? `0${endMinute}` : endMinute}:00`;
    };
    
    const hoursDay = Array.from({ length: 24 }, (_, index) => index);


    return (
        <Fragment>
            <HorarioInputs
                isEntrada={isEntrada}
                selectedDay={selectedDay}
                intervals={intervals}
                open={openModal}
                handleClose={closeModal}
                handleSave={handleLocalSave}
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-startTime',
                    flexDirection: 'column',
                    width: mobile ? '330px' : isSmall ? '400px' : isMedium ? '480px' : '602px',
                }}
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={1}
                    />
                    <Grid
                        item
                        xs={isMedium ? 10 : 11}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {hoursDay.map((_, index) => {
                            if (isMedium ? index % 4 !== 0 : index % 2 !== 0)
                                return null
                            return (
                                <Typography
                                    key={index}
                                    align='left'
                                    sx={{
                                        width: mobile ? '22px' : isSmall ? '26px' : isMedium ? '33px':'44px',
                                    }}
                                >
                                    {index + "h"}
                                </Typography>
                            )
                        })}
                        <Typography
                            align='left'
                            sx={{
                                width: mobile ? '22px' : isSmall ? '26px' : isMedium ? '33px':'44px',
                            }}
                        >
                            24h
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                    />
                </Grid>
                {Object.keys(weekDays).map((day: string) => (
                    <Box
                        key={day}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            margin: '1px',
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            flexWrap="nowrap"
                        >
                            <Grid
                                item
                                xs={1}
                            >
                                <Typography
                                    align='left'
                                >
                                    {weekDays[day].substring(0, 3)}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={isMedium ? 10 : 11}
                                sx={{
                                    display: '-webkit-box',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    WebkitBoxPack: 'start',
                                }}
                            >
                                {hoursDay.map((_, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: mobile ? '11px' : isSmall ? '13px' : isMedium ? '16px':'22px',
                                            width: mobile ? '11px' : isSmall ? '13px' : isMedium ? '16px':'22px',
                                            border: '1px solid #ccc',
                                            borderRadius: '20%',
                                            position: 'relative',
                                            zIndex: 2,
                                        }}
                                    >
                                    </Box>
                                ))}
                                {intervals.map((interval, index) => {
                                    if (interval[daysEnum[weekDays[day.toString()]]]) {
                                        const [hours, minutes] = interval.startTime.split(':');
                                        const [endHours, endMinutos] = interval.endTime.split(':');
                                        const floatStart = parseFloat(hours) + parseFloat(minutes) / 60;
                                        const floatEnd = parseFloat(endHours) + parseFloat(endMinutos) / 60;
                                        const maxRight = multiplier * 24 - ((parseFloat(endHours) * 60 + parseFloat(endMinutos)) - (parseFloat(hours) * 60 + parseFloat(minutes))) * multiplier / 60;
                                        const size = Math.round((floatEnd - floatStart) * multiplier).toString() + 'px';
                                        return (
                                            <Draggable
                                                key={index}
                                                axis="x"
                                                bounds={{ left: 0, right: maxRight }}
                                                position={{ x: floatStart * multiplier, y: 0 }}
                                                onDrag={(e: any, ui: any) => handleDrag(ui, interval.id, daysEnum[weekDays[day]])}
                                            >
                                                <div
                                                    className='interval'
                                                    style={{ width: size}}
                                                >
                                                    <Tooltip
                                                        title={`${interval.startTime} - ${interval.endTime}`}
                                                    >
                                                        <div
                                                            className='intervalToolTip'
                                                            style={{ width:size}}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </Draggable>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </Grid>
                            <Grid
                                item
                                xs={1}
                            >
                                <IconButton
                                    type='button'
                                    aria-label="config"
                                    size="small"
                                    onClick={() => setModalSettings(isEntrada, daysEnum[weekDays[day]])}
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </Box>
        </Fragment>
    )
}
export default WeekComponent