import {ApolloClient, gql, NormalizedCacheObject} from "@apollo/client";
import { dispatchGraphError } from '../utils/dispatchGraphError'

export async function GetYear (year) {
  /** @type ApolloClient<NormalizedCacheObject> */
      // @ts-ignore
  const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
  const {
    data: {
      getYear
    },
    errors
  } = await client.query({
      query: gql`
            query GetYear($year: String) {
                getYear(year: $year) {
                    codigo
                    id
                    razaoStatus {
                        valor
                        label
                    }
                    Status {
                        valor
                        label
                    }
                }
            }
        `,
        variables: { year: year }
    })

    //@ts-ignore
    dispatchGraphError(errors)

    return getYear
}
