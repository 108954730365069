import { useEffect, useState, Fragment } from 'react';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import withAppContext from "../../hocs/withAppContext";
import moment from "moment-timezone";
import QRCode from 'qrcode';
import * as _matriculaService from "../../service/matricula-service";
import * as _contatosService from "../../service/contatos-service";
import * as _carteirinhaTemplate from "../../service/carterinha-service";
import { Matricula } from "../../components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";
import { Unidade } from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";

import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import LoadingAnimation from '../../components/LoadingAnimation/LoadigAnimation';
import { Grid, TextField, Typography, Box, Button, Stack, Grow } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import './styles.css';
import { Redirect } from 'react-router-dom';

interface Props {
  Matricula: Matricula,
  Unidade: Unidade
  handleShowCsat: (key: string) => void
}

const CarteirinhaEscolar = (props: Props) => {
  const { Matricula, Unidade } = props;
  const [nomeServico, setNomeServico] = useState<string>(null);
  const [email, setEmail] = useState<string>(null);
  const [cpf, setCpf] = useState<string>(null);
  const [rg, setRg] = useState<string>(null);
  const [redirectToCadastro, setredirectToCadastro] = useState<boolean>(false);
  const [htmlContent, sethtmlContent] = useState<string>(null);
  const [htmlVerso, setHtmlVerso] = useState<string>(null);
  const [errorText, setErrorText] = useState<string>(null);
  const [isloading, setIsLoading] = useState<boolean>(true);
  const [erroRequisicao, setErroRequisicao] = useState<boolean>(false);
  const [erroRequisicaoImagem, setErroRequisicaoImagem] = useState<boolean>(false);
  moment.tz.setDefault("America/Sao_Paulo");

  useEffect(() => {
    setErroRequisicao(false);
    setErroRequisicaoImagem(false);
    Promise.all([getHtmlTemplate()]);
  }, [Matricula])

  const replaceValues = (html, values) => {
    const regex = /{{(.*?)}}/g;
    const matches = html.match(regex);

    if (matches) {
      matches.forEach(match => {
        const key = match.slice(2, -2).trim();
        if (values.hasOwnProperty(key)) {
          html = html.replace(match, values[key]);
        }
      });
    }
    return html;
  }
  const filterName = async (nomeCompleto: string) => {
    const partesNome = nomeCompleto.split(' ');
    const primeiroNome = partesNome.shift();
    const nomesMeioAbreviados = partesNome.slice(0, -1).map(nome => `${nome.charAt(0)}.`);
    const ultimoSobrenome = partesNome.pop();
    const nomeAbreviado = [primeiroNome, ...nomesMeioAbreviados, ultimoSobrenome].join(' ');

    return nomeAbreviado;
  };


  const getHtmlTemplate = async () => {
    try {
      setIsLoading(true);
  
      const contato = await _contatosService.GetByContatoMatriculaCRM(Matricula.idMatricula);
      const unidadeTemplates = await _carteirinhaTemplate.getCarteirinhaTemplateByUnidade(Unidade.id);
  
      const getHtmlByURL = async (url) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Erro ao buscar o template na URL: ${url}`);
          }
          return {
            htmlText: await response.text(),
            url: url,
          };
        } catch (error) {
          throw new Error(`Erro ao buscar o template da URL: ${url}.`);
        }
      };
  
      let validURL = unidadeTemplates.find(template => template.urlFrente && template.urlVerso);
      if (!validURL) validURL = unidadeTemplates.find(template => template.urlFrente);
  
      if (!validURL) {
        throw new Error("Não foi possível encontrar o template da carteirinha");
      }
  
      const template = await getHtmlByURL(validURL.urlFrente);
      const templateVerso = validURL?.urlVerso ? await getHtmlByURL(validURL.urlVerso) : null;
  
      const matricula = await _matriculaService.GetMatricula(Matricula.idMatricula);
  
      if (!template) {
        throw new Error("Erro ao buscar o template da carteirinha");
      } else if (!contato) {
        throw new Error("Erro ao buscar o contato do aluno no CRM");
      } else if (!contato[0]?.fotoPrincipal?.publicURL) {
        setErroRequisicaoImagem(true);
        setErrorText('Sem foto Cadastrada!');
        setIsLoading(false);
        return;
      }
  
      let qrCodeInfo = {
        school: Unidade.nome,
        enrollment: Matricula.codigoMatricula,
        name: Matricula.estudante_Name,
        document: contato ? contato[0]?.rg ?? contato[0]?.cpf : null,
        issued: moment().valueOf().toString(),
        expires: moment(matricula.dataTermino).add(2, 'months').valueOf().toString(),
      };
  
      if (!nomeServico) setNomeServico(Matricula.servico.split('-')[1]);
      if (contato && contato[0]?.rg) setRg(contato[0]?.rg);
      if (contato && contato[0]?.cpf) setCpf(contato[0]?.cpf);
      if (contato && contato[0]?.email) setEmail(contato[0]?.email);
  
      let docType = null;
      let doc = null;
      if (contato && contato[0]?.rg) {
        docType = 'RG';
        doc = contato[0]?.rg;
      } else if (contato && contato[0]?.cpf) {
        docType = 'CPF';
        doc = contato[0]?.cpf;
      }
  
      const urlRequest = await _matriculaService.generateQrCodeAuthorization(qrCodeInfo);
      const dataFormatada = moment(contato[0]?.dataNascimento).format("DD/MM/YYYY");
  
      let studentInfo = replaceValues(template.htmlText, {
        SCHOOLNAME: Unidade.nome,
        YEAR: matricula.anoLetivo_Name,
        SERVICE: matricula.curso_Name,
        ENROLLMENT: Matricula.codigoMatricula,
        BIRTHDATE: dataFormatada ?? '',
        EDUCATIONLEVEL: matricula.segmento_Name,
        STUDENTNAME: await filterName(Matricula.estudante_Name),
        RA: Matricula.codigoMatricula,
        MAINDOCTYPE: docType ?? '',
        MAINDOC: doc ?? null,
        EXPIRATION: moment(matricula.dataTermino).add(2, 'months').format("MM/YYYY"),
        PHOTOURL: contato[0]?.fotoPrincipal?.publicURL,
        QRCODE: await QRCode.toDataURL(urlRequest.url),
      });
  
      sethtmlContent(studentInfo);
      if (templateVerso && templateVerso.htmlText) setHtmlVerso(templateVerso.htmlText);
  
      setIsLoading(false);
  
    } catch (error) {
      setErroRequisicao(true);
      setErrorText(error.message);
      setIsLoading(false);
    }
  };  

  const handleRedirect = () => {
    setredirectToCadastro(true);
  }

  const printPDF = () => {
    window.print();
  };


  if (erroRequisicaoImagem) {
    return (
      <Fragment>
        <ErrorMessage message={errorText} />
        {redirectToCadastro && <Redirect to="/reconhecimento-facial/alunos" />}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            component="label"
            variant="contained"
            color='primary'
            onClick={handleRedirect}
            endIcon={<HowToRegIcon />}
          >
            Cadastrar Imagem
          </Button>
        </Box>
      </Fragment>
    )
  }
  if (erroRequisicao) {
    return <ErrorMessage message={errorText} />
  }

  if (isloading) {
    return (<LoadingAnimation> </LoadingAnimation>)
  }
  return (
    <Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Stack sx={{ mb: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Stack>
              <Typography
                variant="h4"
              >
                Carteirinha Digital
              </Typography>
            </Stack>
            <Stack spacing={2} direction="row">
              <Button
                component="label"
                variant="contained"
                color='primary'
                onClick={printPDF}
                startIcon={<PrintIcon />}
              >
                Imprimir
              </Button>
            </Stack>
          </Box>
        </Stack>
        <Grow
          in={!isloading}
          easing="ease-out"
          mountOnEnter
          unmountOnExit
          timeout={800}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              lg={8}
              xl={6}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  p: 3,
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
                }}
              >
                <div id='carteirinha-container' className="carteirinha-container" dangerouslySetInnerHTML={{ __html: htmlContent }}>
                </div>
                {htmlVerso && 
                  <div id='carteirinha-verso' className="carteirinha-verso" dangerouslySetInnerHTML={{ __html: htmlVerso }}>
                  </div>
                }
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              xl={6}
            >
              <Stack
                spacing={3}
                sx={{ mb: 3 }}
              >
                <TextField
                  id="outlined-basic"
                  variant='standard'
                  label="Nome do Serviço"
                  disabled
                  value={nomeServico}
                  onChange={(event) => setNomeServico(event.target.value)}
                  fullWidth
                />
              </Stack>
              <Stack
                spacing={3}
                sx={{ mb: 3 }}
              >
                <TextField
                  id="outlined-basic"
                  variant='standard'
                  label="Email"
                  disabled
                  value={email}
                  fullWidth
                />
              </Stack>
              <Stack
                spacing={3}
                sx={{ mb: 3 }}
              >
                <TextField
                  id="outlined-basic"
                  variant='standard'
                  label="RG"
                  disabled
                  value={rg}
                  fullWidth
                />
                <TextField
                  id="outlined-basic"
                  variant='standard'
                  label="CPF"
                  disabled
                  value={cpf}
                  fullWidth
                />
              </Stack>
              <Stack
                spacing={3}
                sx={{ mb: 3 }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: 'text.secondary',
                    fontStyle: 'italic',
                    textAlign: 'flex-start'
                  }}
                >
                  *Dados provenientes do CRM.
                </Typography>
              </Stack>

            </Grid>
          </Grid>
        </Grow>
      </Box>
    </Fragment>
  );
}

export default withAppContext(withUnidadeMatricula(CarteirinhaEscolar, 'Carteirinha Escolar'));