import React, { Fragment } from 'react'
import { Typography, Box, Grid, useMediaQuery } from '@mui/material'
import './intervalBar.css';
import { Tooltip } from '@mui/material';
import { Intervals } from "../../types/guardian-types";
import { useTheme } from '@mui/material/styles';

const weekDays: Record<string, string> = {
    "0": 'Segunda',
    "1": 'Terça',
    "2": 'Quarta',
    "3": 'Quinta',
    "4": 'Sexta',
    "5": 'Sábado',
    "6": 'Domingo',
}

const daysEnum = {
    'Segunda': 'isMonday',
    'Terça': 'isTuesday',
    'Quarta': 'isWednesday',
    'Quinta': 'isThursday',
    'Sexta': 'isFriday',
    'Sábado': 'isSaturday',
    'Domingo': 'isSunday',
}

interface Props {
    intervals: Intervals[];
}

const initialInterval : Intervals = {
    startTime: '00:00:00',
    endTime: '00:00:00',
    id: 0,
    isFriday: false,
    isMonday: false,
    isSaturday: false,
    isSunday: false,
    isThursday: false,
    isTuesday: false,
    isWednesday: false,
}
export const WeekComponent = (props: Props) => {
    const { 
        intervals
    } = props;
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const hoursDay = Array.from({ length: 24 }, (_, index) => index);

    return (
        <Fragment>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-startTime',
                    flexDirection: 'column',
                    width: isSmall ? '361px' : isMedium ? '450px' : '602px',
                }}
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={1}
                    />
                    <Grid
                        item
                        xs={isMedium ? 10 : 11}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {hoursDay.map((_, index) => {
                            if (isMedium ? index % 4 !== 0 : index % 2 !== 0)
                                return null
                            return (
                                <Typography
                                    key={index}
                                    align='left'
                                    sx={{
                                        width: isSmall ? '26px' : isMedium ? '33px':'44px',
                                    }}
                                >
                                    {index + "h"}
                                </Typography>
                            )
                        })}
                        <Typography
                            align='left'
                            sx={{
                                width: isSmall ? '26px' : isMedium ? '33px':'44px',
                            }}
                        >
                            24h
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                    />
                </Grid>
                {Object.keys(weekDays).map((day: string) => (
                    <Box
                        key={day}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            margin: '1px',
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                        >
                            <Grid
                                item
                                xs={1}
                            >
                                <Typography
                                    align='left'
                                >
                                    {weekDays[day].substring(0, 3)}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={isMedium ? 8 : 11}
                                sx={{
                                    display: '-webkit-box',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    WebkitBoxPack: 'start',
                                }}
                            >
                                {hoursDay.map((_, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: isSmall ? '13px' : isMedium ? '16px':'22px',
                                            width: isSmall ? '13px' : isMedium ? '16px':'22px',
                                            border: '1px solid #ccc',
                                            borderRadius: '20%',
                                            position: 'relative',
                                            zIndex: 2,
                                        }}
                                    >
                                    </Box>
                                ))}
                                {intervals.map((interval, index) => {
                                    const [hours, minutes] = interval.startTime.split(':');
                                    const [endHours, endMinutos] = interval.endTime.split(':');
                                    const floatStart = parseFloat(hours) + parseFloat(minutes) / 60;
                                    const floatEnd = parseFloat(endHours) + parseFloat(endMinutos) / 60;
                                    const multiplier = isSmall ? 13 : isMedium ? 16 : 22;
                                    if (interval[daysEnum[weekDays[day.toString()]]]) {
                                        return (
                                            <div
                                                key={index}
                                                className='visualInterval'
                                                style={{ width: (floatEnd - floatStart) * multiplier }}
                                            >
                                                <Tooltip
                                                    title={`${interval.startTime} - ${interval.endTime}`}
                                                >
                                                    <div
                                                        className='intervalToolTip'
                                                        style={{ width: (floatEnd - floatStart) * multiplier }}
                                                    />
                                                </Tooltip>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </Box>
        </Fragment>
    )
}
export default WeekComponent